import React from "react";
import M from "materialize-css";
import firebase from "firebase/app";
import "firebase/functions";
import "./contactus.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
const submitContact = (e) => {
  e.preventDefault();
  let emailBox = e.target.elements["email"];
  let messageBox = e.target.elements["message"];
  let functions = firebase.app().functions("europe-west1");
  let sendEmailEnquiry = functions.httpsCallable("sendEmailEnquiry");

  sendEmailEnquiry({
    email: emailBox.value,
    message: messageBox.value,
  })
    .then((result) => {
      M.toast({ html: "Message sent!" });
      emailBox.value = "";
      messageBox.value = "";
      M.textareaAutoResize(emailBox);
      M.textareaAutoResize(messageBox);
      M.updateTextFields();
    })
    .catch((error) => {
      M.toast({
        html:
          "Error sending message. Please contact the team at admin@lunite.co.uk",
      });
    });
};
const ContactUs = () => {
  return (
    <section id="contact" className="container section scrollspy center-align">
      <h1 className="lunite-header-font">Contact Lunite</h1>
      <p className="flow-text">Get in touch for quotes and enquiries</p>
      <div className="row">
        <div className="col s12 m12">
          <div className="row">
            <div className="col s3"></div>
            <div className="col s6">
              <div>
                <FontAwesomeIcon size="lg" icon={faEnvelope} /> : <span>enquiries@lunite.co.uk</span>
              </div>
              {/* <form onSubmit={submitContact}>
                <div className="input-field">
                  <i className="material-icons prefix">email</i>
                  <input type="email" id="email" name="email" />
                  <label htmlFor="email">Your email</label>
                </div>
                <div className="input-field">
                  <i className="material-icons prefix">message</i>
                  <textarea
                    id="message"
                    data-length="120"
                    name="message"
                    className="materialize-textarea"
                  />
                  <label htmlFor="message">Your message</label>
                </div>
                <div className="input-field center">
                  <button type="submit" className="btn grey darken-4">
                    Submit
                  </button>
                </div>
              </form> */}
            </div>
            <div className="col s3"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
