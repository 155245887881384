// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

import React, { Component } from "react";
import Header from "./sections/header/header";
import Parallax from "./sections/parallax/parallax";
import About from "./sections/about/about";
import ContactUs from "./sections/contactus/contactus";
import Footer from "./sections/footer/footer";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css";
import Design from "./sections/design/design";
import Develop from "./sections/develop/develop";
import Deliver from "./sections/deliver/deliver";

class App extends Component {
  componentDidMount() {
    M.AutoInit();
    const firebaseConfig = {
      apiKey: "AIzaSyCfCwarxxkgPkE7bYTDASeqzbp16wbkW3M",
      authDomain: "lunite-landing-32ef6.firebaseapp.com",
      projectId: "lunite-landing-32ef6",
      storageBucket: "lunite-landing-32ef6.appspot.com",
      messagingSenderId: "306665182730",
      appId: "1:306665182730:web:bf2212592fb2bb07edf1f3"
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  }

  render() {
    return (
      <div className="App">
        <Header />
        <About />
        <Parallax
          src={"https://images.hdqwalls.com/wallpapers/imac-pro-5k-ad.jpg"}
        />
        <Design />
        <Parallax
          src={"https://images.hdqwalls.com/wallpapers/imac-pro-5k-ad.jpg"}
        />
        <Develop />
        <Parallax
          src={"https://images.hdqwalls.com/wallpapers/imac-pro-5k-ad.jpg"}
        />

        <Deliver />
        <Parallax
          src={"https://images.hdqwalls.com/wallpapers/imac-pro-5k-ad.jpg"}
        />
        <ContactUs />
        <Footer />
      </div>
    );
  }
}

export default App;
