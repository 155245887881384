// Dont judge this i know we need to programmatically generate this but copy and paste was easier at the time ;)
import React, { useState } from "react";
import "./develop.css";

const Design = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <section id="develop" className="container section scrollspy center-align">
      <h1 className="lunite-header-font">
        Development across multiple platforms
      </h1>
      <p className="flow-text">
        We develop bespoke solutions to help your business achieve success.
      </p>
      <div className="row">
        <div className="col s12 m4">
          <div className="row">
            <div className="col s12">
              <i className="material-icons md-dark md-48">web</i>
            </div>
            <div className="col s12">
              <h5>Web Applications</h5>
              <p>
                Simplify your processes with internal and customer facing
                progressive web apps
              </p>
            </div>
          </div>
        </div>
        <div className="col s12 m4">
          <div className="row">
            <div className="col s12">
              <i className="material-icons md-dark md-48">smartphone</i>
            </div>
            <div className="col s12">
              <h5>Mobile Applications</h5>
              <p>Android and iOS mobile application development</p>
            </div>
          </div>
        </div>
        <div className="col s12 m4">
          <div className="row">
            <div className="col s12">
              <i className="material-icons md-dark md-48">memory</i>
            </div>
            <div className="col s12">
              <h5>IOT</h5>
              <p>
                Bring your company into the 21st century with the Internet Of
                Things
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`example ${expanded ? "" : "collapsed"}`}>
        <div className="row" key="hasdhasdh">
          <div className="col s12 m4">
            <div className="row">
              <div className="col s12">
                <i className="material-icons md-dark md-48">school</i>
              </div>
              <div className="col s12">
                <h5>Machine Learning</h5>
                <p>
                  Leverage AI and ML to give your business competitive advantage
                </p>
              </div>
            </div>
          </div>
          <div className="col s12 m4">
            <div className="row">
              <div className="col s12">
                <i className="material-icons md-dark md-48">shopping_basket</i>
              </div>
              <div className="col s12">
                <h5>E-Commerce</h5>
                <p>Personalized e-commerce sites to promote your business</p>
              </div>
            </div>
          </div>
          <div className="col s12 m4">
            <div className="row">
              <div className="col s12">
                <i className="material-icons md-dark md-48">insert_chart</i>
              </div>
              <div className="col s12">
                <h5>Data Analytics</h5>
                <p>Analyze and understand data to make informed decisions </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m4">
            <div className="row">
              <div className="col s12">
                <i className="material-icons md-dark md-48">
                  perm_contact_calendar
                </i>
              </div>
              <div className="col s12">
                <h5>Booking Systems</h5>
                <p>Manage clients with personalized booking systems</p>
              </div>
            </div>
          </div>
          <div className="col s12 m4">
            <div className="row">
              <div className="col s12">
                <i className="material-icons md-dark md-48">business</i>
              </div>
              <div className="col s12">
                <h5>Warehouse Management</h5>
                <p>
                  Keep track of stock with stock management tools tailored for
                  your use cases
                </p>
              </div>
            </div>
          </div>
          <div className="col s12 m4">
            <div className="row">
              <div className="col s12">
                <i className="material-icons md-dark md-48">more_horiz</i>
              </div>
              <div className="col s12">
                <h5>Many More</h5>
                <p>
                  We do much more and are always happy to help so please get in
                  touch
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <button
          className="btn-floating btn-large waves-effect waves-light transparent"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <i className="noclick black-text transparent material-icons">
            {expanded ? "expand_less" : "expand_more"}
          </i>
        </button>
      </div>
    </section>
  );
};

export default Design;
