import React from "react";
import "./about.css";

const About = () => {
    return (
        <section id="info" className="container section scrollspy center-align">
          <h1 className="lunite-header-font">Lunite Software</h1>
          <p className="flow-text">We build beautiful software for your business needs.</p>
          <div className="row">
            <div className="col s12 m4">
              <div className="row">
                <div className="col s12">
                  <i className="material-icons md-dark md-48">public</i>
                </div>
                <div className="col s12">
                  <h5>World-class Software</h5>
                  <p>
                    We don&apos;t settle for anything less than the best. We use cutting edge cloud technology to ensure that every
                    solution we deliver is secure, scalable, and fit for purpose.
                  </p>
                </div>
              </div>
            </div>
            <div className="col s12 m4">
            <div className="row">
                <div className="col s12">
                  <i className="material-icons md-dark md-48">people</i>
                </div>
                <div className="col s12">
                  <h5>Collaborative</h5>
                  <p>
                    We deliver exactly the software that your business needs. Exactly as you need it, every single time.
                    We work closely with each customer to ensure we know precicely what needs to be built.
                  </p>
                </div>
              </div>
            </div>
            <div className="col s12 m4">
            <div className="row">
                <div className="col s12">
                  <i className="material-icons md-dark md-48">engineering</i>
                </div>
                <div className="col s12">
                  <h5>Engineers at Heart</h5>
                  <p>
                    We are engineers. At Lunite we hold great pride in what we do. Our software keeps the world turning. And we&apos;re proud to be a part of it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
}

export default About;