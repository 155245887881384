import { Navbar } from "./../../components/navbar/navbar";
import React, { Component } from "react";
import "./header.css";
import logo_circle from "../../img/lunite_logo2.svg";
import Particles from "react-particles-js";

class Header extends Component {
  render() {
    return (
      <section id="home" className="scrollspy">
        <Navbar />
        <header className="grey darken-4">
          <Particles
            className="particles"
            params={{
              particles: {
                number: {
                  value: 100,
                  density: {
                    enable: true,
                    value_area: 1500,
                  },
                },
                line_linked: {
                  enable: true,
                  opacity: 0.02,
                },
                move: {
                  speed: 1,
                },
                size: {
                  value: 2,
                },
              },
              interactivity: {
                events: {
                  onclick: {
                    enable: true,
                    mode: "push",
                  },
                  onhover: {
                    enable: true,
                    mode: "repulse",
                  },
                },
                modes: {
                  push: {
                    particles_nb: 1,
                  },
                },
              },
              retina_detect: true,
            }}
          />
          <div className="logo-wrapper">
            <img alt="lunite logo" src={logo_circle} className="logo" />
          </div>
          <div className="header-button-wrapper">
            <a
              href="#info"
              className="btn-floating btn-large waves-effect waves-light transparent"
            >
              <i className="noclick white-text transparent material-icons">
                arrow_downward
              </i>
            </a>
          </div>
        </header>
      </section>
    );
  }
}

export default Header;
