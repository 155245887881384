import React, { Component } from "react";
import "./navbar.css";
import logo_rect from "../../img/lunite_logo.svg";

export class Navbar extends Component {
  state = {
    show: false,
    scrollPos: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    this.setState({
      show:
        document.documentElement.scrollTop >
        document.documentElement.clientHeight / 2,
    });
  }

  render() {
    let classes = "navbar-fixed lunite-nav ";
    if (!this.state.show) {
      classes = classes + "hide";
    }

    return (
      <div className={classes}>
        <nav className="nav-wrapper grey darken-4">
          <div className="container">
            <a href="#home" className="brand-logo">
              <img
                alt="lunite logo"
                src={logo_rect}
                className="logo-rect noclick"
              />
            </a>
            <ul className="right hide-on-med-and-down">
              <NavItems />
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

function NavItems() {
  return (
    <>
      <li>
        <a href="#design">Design</a>
      </li>
      <li>
        <a href="#develop">Develop</a>
      </li>
      <li>
        <a href="#deliver">Deliver</a>
      </li>
      <li>
        <a href="#contact">Contact</a>
      </li>
    </>
  );
}
