import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
class Footer extends Component {
  render() {
    return (
      <footer className="page-footer grey darken-2">
        <div className="container">
          <div className="row">
            <div className="col s12 l6"></div>
            <div className="col s12 l4 offset-l2">
              <h5>Connect</h5>
              <ul>
                <li>
                  <div>
                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                    <a
                      className="white-text"
                      href="mailto:enquiries@lunite.co.uk"
                    >
                      Email
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright grey darken-4">
          <div className="container center-align">2021 Lunite Ltd</div>
        </div>
      </footer>
    );
  }
}

export default Footer;
