import React from "react";
import "./design.css";

const Design = () => {
  return (
    <section id="design" className="container section scrollspy center-align">
      <h1 className="lunite-header-font">Design is key to success</h1>
      <p className="flow-text">
        We design products that look good and perform.
      </p>
      <div className="row">
        <div className="col s12 m4">
          <div className="row">
            <div className="col s12">
              <i className="material-icons md-dark md-48">web</i>
            </div>
            <div className="col s12">
              <h5>User Interface</h5>
              <p>
                We produce stunning User Interfaces making our systems a
                pleasure to work with. All our tools conform to the WCAG 2.1
                accessibility standards.
              </p>
            </div>
          </div>
        </div>
        <div className="col s12 m4">
          <div className="row">
            <div className="col s12">
              <i className="material-icons md-dark md-48">dashboard</i>
            </div>
            <div className="col s12">
              <h5>User Experience</h5>
              <p>
                Focusing on User Experience allows us to optimise process flow
                and efficiency.
              </p>
            </div>
          </div>
        </div>
        <div className="col s12 m4">
          <div className="row">
            <div className="col s12">
              <i className="material-icons md-dark md-48">engineering</i>
            </div>
            <div className="col s12">
              <h5>Architecture</h5>
              <p>
                We produce intricate architecture diagrams helping you
                understand exactly what's going on behind the scenes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Design;
