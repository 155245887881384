import React, { Component } from "react";

class Parallax extends Component {
  render() {
    return (
      <section>
        <div className="parallax-container hide-on-small-only">
          <div className="parallax">
            <img alt="parallax" src={this.props.src} />
          </div>
        </div>
      </section>
    );
  }
}

export default Parallax;
