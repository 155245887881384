import React from "react";
import "./deliver.css";

const Deliver = () => {
  return (
    <section id="deliver" className="container section scrollspy center-align">
      <h1 className="lunite-header-font">
        Delivery when and where you require
      </h1>
      <p className="flow-text">We deliver and support our product with ...</p>
      <div className="row">
        <div className="col s12 m4">
          <div className="row">
            <div className="col s12">
              <i className="material-icons md-dark md-48">cloud</i>
            </div>
            <div className="col s12">
              <h5>Cloud</h5>
              <p>
                Cloud deployment for cost effective infrastructure with global
                reach
              </p>
            </div>
          </div>
        </div>
        <div className="col s12 m4">
          <div className="row">
            <div className="col s12">
              <i className="material-icons md-dark md-48">desktop_windows</i>
            </div>
            <div className="col s12">
              <h5>On Premise</h5>
              <p>Deploy software to on prem hardware remote or in person</p>
            </div>
          </div>
        </div>
        <div className="col s12 m4">
          <div className="row">
            <div className="col s12">
              <i className="material-icons md-dark md-48">engineering</i>
            </div>
            <div className="col s12">
              <h5>Support</h5>
              <p>A number of support plans can be discussed</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Deliver;
